@import url("https://fonts.googleapis.com/css?family=Audiowide|Karla|Markazi|Montserrat");

/* Global Structural Layouts */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.base-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  gap: 20px;
}

.base-container {
  grid-column-start: 2;
  grid-column-end: 12;
  height: 100%;
}

.base-container-narrow {
  grid-column-start: 3;
  grid-column-end: 11;
  height: 100%;
}

.homepage {
  overflow: auto;
}

/* Text Styles */

body {
  font-family: 'Montserrat', 'Tahoma', 'Verdana', 'sans-serif', 'Karla';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav ul,
nav a,
nav li,
.link {
  list-style-type: none;
  text-decoration: none;
}

.box-shadow {
  box-shadow: 0px 0px 10px 5px #afb1b6; 
}

.img-radius {
  border-radius: 15px;
}

.btn {
  color: #FFFFFF;
  padding: 12px 24px;
  background-color: #3E8DE3;
  border: none;
  border-radius: 8px;
  transition: .4s;
  cursor: pointer;
  font-size: calc(18px + 0.390625vw);
  font-weight: 800;
  font-family: 'Montserrat';
  font-weight: 800;
  text-decoration: none;
}

.btn:active {
  transform: translate(4px);
  box-shadow: 0px 0px 1px rgba(0,0,0,0,.2);
  color: #04060D;
  background-color: #D3D4D7;
  border-bottom: none;
}

.icon {
  transition: .4s;
  cursor: pointer;
}

.icon:active {
  transform: translate(5px);
  box-shadow: 0px 0px 1px rgba(0,0,0,0,.2);
  border-bottom: none;
  color: #D3D4D7;
}

/* Responsive Font Sizes */

body p, body span, body .list-text,
span > .bold {
  /* Calculates font size as 15px plus 0.00390625% screen width */
  font-size: calc(16px + 0.390625vw);
  font-family: 'Montserrat', 'Tahoma', 'Verdana', 'sans-serif', 'Karla';
}

