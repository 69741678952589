.experience {
  height: 100%;
  width: 100%;
  min-height: 95vh;
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(to left, rgba(0, 0, 0, 1) 1%, rgba(0, 255, 255, 1) 50%, rgba(0, 0, 0, 1) 100%) 
                100% 0 100% 0/4px 0 0 0 stretch;
  position: relative;
  z-index: 1;
  background-color: #143AA2;
  padding-bottom: calc(90px + 0.390625vw);
}

.experience .base-container {
  height: 100%;
  z-index: 2;
}

.experience #scroll-instruction {
  display: flex;
  justify-content: left;
  align-items: last center;
  font-size: calc(35px + 0.390625vw);
  color: #ffffff;
}

#scroll-instruction h3 {
  font-size: calc(20px + 0.390625vw);
  font-weight: 300;
  padding-left: .5rem;
  font-family: 'Montserrat';
}
