.navbar {
  height: 90px;
  background-color: rgba(211, 212, 215, 0.9);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
  margin-top: 0;
  position: fixed;
  z-index: 1000;  
}

.navbar .base-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .nav-menu {
  display: flex;
  flex-direction: row;
}

.navbar nav,
.navbar ul,
.navbar li,
.navbar a {
  font-size: calc(18px + 0.390625vw);
  color: #04060D;
}

.navbar #socials a {
  padding-right: 1.5rem;
}
.navbar #site-links li {
  padding-left: 1.5rem;
}

.navbar .hamburger {
  display: none;
}

/* Color the currently active link */
.navbar .active-loc {
  color: #ffffff;
  font-weight: 600;
  background-color: #143aa2;
  border-radius: 10px;
  padding: .5rem;
}

@media screen and (max-width:1250px){
  .navbar .hamburger {
    display: block;
    font-size: calc(26px + 0.390625vw);
  }

  .navbar .nav-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 90px;
    right: -100%;
    height: 100vh;
    background: rgba(193, 191, 191, 0.9);
    width: 100%;
    transition: 0.4s ease-in-out;
    z-index: 10;
  }

  .navbar .active {
    right: 0;
  }

  .navbar .nav-menu,
  .navbar li {
    padding: 1rem;
  }
}