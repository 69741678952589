.footer {
  height: 100%;
  min-height: 220px;
  margin-top: 0;
  left: 0;
  margin-bottom: 0;
  background-color: #ececec;
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(to left, rgba(0, 0, 0, 1) 1%, rgba(0, 255, 255, 1) 50%, rgba(0, 0, 0, 1) 100%) 
                100% 0 100% 0/4px 0 0 0 stretch;
  position: relative;
  z-index: 5;
}

.footer .base-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: top;
  text-align: left;
  margin: 2rem 0 2rem;
}

.footer col {
  margin: auto;
}

.footer .centre-align {
  text-align: center;
}

.footer h2 {
  font-size: calc(14px + 0.390625vw); /* Make font-size responsive to viewport width */
  font-family: 'AudioWide';
  color: #143AA2;
}

.footer p,
.footer a,
.footer .li {
  font-size: calc(11px + 0.390625vw);
  line-height: 1.8rem;
  color: #04060D;
}

.footer .btn {
  font-size: calc(12px + 0.390625vw);
  padding: .8rem;
  margin-top: .5rem;
}

@media screen and (max-width:950px) {

  .footer {
    height: 100%;
  }

  .footer .base-container {
    display: grid;
    grid-template-columns: repeat(1, fr);
    gap: .5rem;
  }
}