/* Page Layout */

.experience-entry {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #04060D;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: auto;
}

.experience-entry .base-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0 60px 0;
}

.experience-entry .base-container-narrow {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 60px 0 30px 0;
}

.experience-entry .center-content {
  text-align: center;
}

.experience-entry .top {
  align-items: first baseline;
  top: 0;
}

.experience-entry .row {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px; 
  margin-bottom: 10px;
}

.grid-row {
  height: 100%;
  width: 100%;
  display: grid;
}

#msc .grid-row {
  grid-template-columns: 2fr 1fr;
}
.ma .grid-row {
  grid-template-columns: 1.5fr 1fr;
  gap: 40px;
}

#bc .grid-row {
  grid-template-columns: 2fr 3fr;
}

#ios .grid-row {
  grid-template-columns: .8fr 1fr;
  gap: 100px;
}


/* Text */


.experience-entry .text-content {
  margin: 20px 0 20px 0;
}

.experience-entry h1 {
  font-size: calc(35px + 0.390625vw);
  margin: 1rem 0 1rem 0;
}

.experience-entry h2 {
  font-size: calc(19pt + 0.390625vw);
  padding: 0 0 .5rem 0;
}

.experience-entry h3 {
  text-align: left;
  font-size: calc(20px + 0.390625vw);
  margin: .5rem 0 .5rem 0;
}

.experience-entry p {
  line-height: 1.8rem;
  padding: 1rem 0 .5rem 0;
}

.experience-entry .list-text {
  line-height: 2.5rem;
}

.space-text li {
  margin-bottom: 10px;
}

.experience-entry .bold {
  font-family: 'Tahoma';
  font-weight: 800;
}

.experience-entry .italic {
  font-style: italic;
}

.experience-entry .no-decoration {
  list-style: none;
  text-decoration: none;
}

.experience-entry .indent-left {
  margin-left: 1.5rem;
}


/* Image Styles */


.experience-entry .image-container {
  position: relative;
  width: 100%; 
  min-height: 50vh;
}

.experience-entry .image-reel {
  grid-column-start: 1;
  grid-column-end: -1;
  display: flex;
  margin-top: 100px;
  margin-bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: last baseline;
  text-align: center;
  object-fit: contain;
}

.experience-entry .img-radius-c {
  border-radius: 35px;
}

.experience-entry .img-abs {
  position: absolute;
}

.experience-entry .tall-page-img {
  height: calc(500px + 1vh);
  margin: 10px 70px 10px 70px;
}

.experience-entry .wide-page-img {
  height: calc(200px + 5vw);
  margin: 50px 50px 0 50px;
}

.experience-entry .tall-img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: calc(300px + 1vh);
}

.experience-entry .wide-img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: calc(270px + 1vw);
}

.experience-entry .capstone-img {
  width: calc(700px + 1vw);
  height: 100%;
  margin: 20px 50px 50px 50px;
  object-fit: cover;
}

/* Image by id */

#msc-imgA {
  width: 450px;
}
#bc-imgA {
  width: calc(350px + 2.5vw);
  top: 10%;
  right: 0;
  z-index: 2;
}

#bc-imgB {
  width: calc(350px + 2.5vw);
  top: 30%;
  left: 10%;
  z-index: 1;
}

#ios-imgA {
  width: calc(320px + 5vw);
  top: 5%;
  right: 0;
  z-index: 0;
}

#ios-imgB {
  width: calc(250px + 5vw);
  top: 15%;
  right: 50%;
  z-index: 1;
}

#ios-imgC {
  width: calc(100px + 1vw);
  top: 62%;
  right: 35%;
  z-index: 2;
}

#analyst-img-friends {
  height: calc(260px + 1vw);
  right: 10px;
  margin-top: 3rem;
}


/* Software Pages */


.software-entry .grid-row-left {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  gap: 80px;
  justify-content: center;
  align-items: center;
}
.software-entry .grid-row-right {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2.2fr;
  gap: 80px;
  justify-content: center;
  align-items: center;
}

.software-entry .image-container {
  height: 300px;
}
.software-entry .grid-row {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.software-entry #analyst-contributions {
  margin-top: 2rem;
}

/* Software Logos */

.software-entry .logo-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 0px;
  justify-content: center;
}

.software-entry .logo-container-top {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.software-entry .logo-container-bottom {
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.software-entry .logo {
  width: calc(95px + 0.3vw);
  margin: 1rem 1rem 1rem 1rem;
}


/* Background Gradients */ 


#mentor {
  background-image:
      radial-gradient(
          circle at 0% 100%,
          #ff3399 0%,
          transparent 25%

      ),
      radial-gradient(
          circle at 100% 0%,
          #ff3399 0%,
          transparent 25%
      );
}

#fuze {
  background-image:
  radial-gradient(
      circle at -10% -15%,
      #ff3399 0%,
      transparent 25%
    ),
    radial-gradient(
      circle at 0% 60%,
      #ff3399 0%,
      transparent 25%
    ),
    radial-gradient(
        circle at 100% 30%,
        #ff3399 0%,
        transparent 25%
    );
}

#star {
  background-image:
    radial-gradient(
      circle at -5% 50%,
      #ff3399 0%,
      transparent 25%
    ),
    radial-gradient(
        circle at 105% 50%,
        #ff3399 0%,
        transparent 25%
    );
}


.software-entry {
  background-image:
    radial-gradient(
      circle at -5% 15%,
      #66d9ff 0%,
      transparent 20%
    ),
    radial-gradient(
      circle at 105% 50%,
      #66d9ff 0%,
      transparent 25%
    ),
    radial-gradient(
      circle at -5% 80%,
      #66d9ff 0%,
      transparent 25%
    );
}

.education-entry {
  background-image:
    radial-gradient(
      circle at -5% 50%,
      #7797ee 0%,
      transparent 25%
    ),
    radial-gradient(
      circle at 105% 50%,
      #7797ee 0%,
      transparent 25%
    );
}


@media screen and (max-width: 1200px) {

  .experience-entry .grid-row,
  .experience-entry .grid-row-left,
  .experience-entry .grid-row-right,
  .experience-entry .row {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .education-entry .image-container img {
    margin: 0px;
  }

  #bc-imgA, #bc-imgB, #ios-imgA, #ios-imgB, #ios-imgC,
  #analyst-img-friends {
    top: 0;
    right: 0;
    left: 0;
    margin-bottom: 10px;
    position: relative;
  }

  #analyst-img-friends {
    margin-top: 0;
  }

  .software-entry #analyst-contributions {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}


@media screen and (max-width: 900px) {
  .capstone-img {
    width: auto;
    max-width: 90vw;
    height: auto;
    margin: 10px 0 10px 0;
  }

}

@media screen and (max-width: 700px) {

  #msc-imgA {
    width: 300px;
  }
}


@media screen and (max-width: 400px) {
  #bc-imgA, #bc-imgB {
    width: calc(300px + 2.5vw);
  }
}