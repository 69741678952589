.overview-card {
  height: 100%;
  width: 800px;
  border-radius: 30px;
  background-color: #e8e8ea;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
}

.overview-card .text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
}

.overview-card .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1.5rem 1rem 1.5rem;
}

.overview-card .row span {
  display: flex;
  align-items: center;
}

.overview-card .grade-icon {
  font-size: calc(28px + 0.390625vw);
  color: rgb(251, 206, 7);
}

.overview-card h1 {
  font-size: calc(34px + 0.390625vw);
  font-weight: 600;
  padding-right: .2rem;
}
.overview-card h2 {
  font-size: calc(26px + 0.390625vw);
  font-weight: 500;
}
.overview-card h3 {
  font-size: calc(20px + 0.390625vw);
  font-weight: 500;
}

.overview-card .link {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: calc(22px + 0.390625vw);
  font-weight: 600;
}
.overview-card .link:hover {
  text-decoration: underline;
  transform: translate(10px);
}

.overview-card .arrow {
  margin-left: .5rem;
}

.overview-card .wide-img {
  grid-area: "image1";
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.overview-card .tall-img {
  grid-area: "image1";
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.overview-card img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.references .overview-card {
  margin: 50px;
}

@media screen and (max-width:900px) {

  .overview-card {
    height: 100%;
    width: 90vw;
  }

  .overview-card .row {
    display: grid;
    grid-template-columns: 1fr;
  }

  .overview-card .link {
    margin-top: 1rem;
  }
}

@media screen and (max-width:500px) {
  .overview-card h3 {
    font-size: calc(18px + 0.390625vw);
  }
}