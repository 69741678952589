.experience-overview {
  height: 100%;
  width: 100%;
  min-height: 70vh;
  position: relative;
  z-index: 1;
  overflow: auto;
}

.experience-overview .base-container-narrow {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.experience-overview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.experience-overview .card {
  margin-bottom: 2rem;
}

.experience-overview .scroll-down {
  margin-top: 30vh;
  grid-column-start: 2;
  text-align: center;
  z-index: 1;
}

.experience-overview .scroll-instruction {
  font-size: calc(30px + 0.390625vw);
  font-weight: 400;
  font-style: italic;
  padding-bottom: calc(5px + 0.390625vw);
}

.experience-overview .scroll-icon {
  font-size: calc(60px + 0.390625vw);
}

.software .scroll-down {
  color: #3aadd3;
}
.education .scroll-down {
  color: #4866b8;
}
.volunteering .scroll-down {
  color: #be1c6d;
}


/* Background Gradients */ 

.software {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  background-image:
    radial-gradient(
        circle at 0% 0%,
        #66d9ff 0%,
        transparent 25%

    ),
    radial-gradient(
        circle at 100% 30%,
        #66d9ff 0%,
        transparent 30%
    ),
    radial-gradient(
        circle at 0% 65%,
        #66d9ff 0%,
        transparent 30%
    ),
    radial-gradient(
        circle at 100% 100%,
        #66d9ff 0%,
        transparent 25%
    );
}

.education {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  background-image:
    radial-gradient(
        circle at 0% 0%,
        #7797ee 0%,
        transparent 25%

    ),
    radial-gradient(
        circle at 100% 30%,
        #7797ee 0%,
        transparent 30%
    ),
    radial-gradient(
        circle at 0% 65%,
        #7797ee 0%,
        transparent 30%
    ),
    radial-gradient(
        circle at 100% 100%,
        #7797ee 0%,
        transparent 25%
    );
}

.volunteering {
  height: 100%;
  width: 100%;
  background-color: #ffffff ;
  background-image:
      radial-gradient(
          circle at 0% 15%,
          #ff3399 0%,
          transparent 25%

      ),
      radial-gradient(
          circle at 100% 45%,
          #ff3399 0%,
          transparent 25%
      ),
      radial-gradient(
          circle at 0% 80%,
          #ff3399 0%,
          transparent 25%
      );
}

.references {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  background-image:
    radial-gradient(
        circle at 0% 0%,
        #648c94 -5%,
        transparent 35%

    ),
    radial-gradient(
        circle at 100% 100%,
        #648c94 -5%,
        transparent 35%
    );
}