@import url("https://fonts.googleapis.com/css?family=Audiowide|Karla|Orbitron");

.hero {
  margin: 0;
  padding: 0;
  color: #ffffff;
  height: 90vh;
  position: relative;
  background-color: #042379;
  z-index: 1;
}

.hero .base-container {
  z-index: 1;
}

.hero .fullscreen-video-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  filter: brightness(60%);
  margin-top: -90px;
  z-index: 0;
}

.hero .base-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.hero .base-container {
  display: grid;
  grid-template-rows: 4.5fr 4fr;
  align-items: last baseline;
  text-align: center; 
}

.hero h1 {
  font-size: calc(60px + 0.390625vw);
  font-family: 'Audiowide';
  color: #ffffff;
}

.hero h2 {
  font-size: calc(26px + 0.390625vw);
  font-weight: 400;
}

.hero nav {
  display: flex;
  justify-content: space-between;
  font-size: calc(45px + 0.390625vw);
  font-weight: 500;
  padding-bottom: 5vh;
  font-family: 'AudioWide';
}

.hero .link {
  display: flex;
  align-items: bottom;
  color: #ffffff;
}

.hero .link:hover {
  text-decoration: underline;
  transform: translate(10px);
}

.hero .arrow {
  font-size: calc(70px + 0.390625vw);
}


@media screen and (max-width:940px) {
  .hero nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

}

@media screen and (max-width:700px) {
  .hero h1 {
    font-size: calc(50px + 0.390625vw);
  }
  .hero h2 {
    font-size: calc(22px + 0.390625vw);
  }
  .hero nav {
    font-size: calc(38px + 0.390625vw);
  }
  .hero .arrow {
    font-size: calc(58px + 0.390625vw);
  }
}

@media screen and (max-width:500px) {
  .hero h1 {
    font-size: calc(44px + 0.390625vw);
  }
  .hero h2 {
    font-size: calc(18px + 0.390625vw);
  }
  .hero nav {
    font-size: calc(30px + 0.390625vw);
  }
  .hero .arrow {
    font-size: calc(45px + 0.390625vw);
  }
}