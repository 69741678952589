.custom-arrow {
  font-family: 'Montserrat';
  color: #ffffff;
}

.custom-arrow-icon {
  font-size: calc(40px + 0.390625vw);
}

.custom-arrow-text {
  font-size: calc(20px + 0.390625vw);
}