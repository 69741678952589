.about {
  min-height: 80vh;
  height: 100%;
  background-image: url('../../../assets/about-img.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(to left, rgba(0, 0, 0, 1) 1%, rgba(0, 255, 255, 1) 50%, rgba(0, 0, 0, 1) 100%) 
                100% 0 100% 0/4px 0 0 0 stretch;
  position: relative;
  z-index: 1;
}

.about .base-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "space content content";
  align-items: center;
  text-align: right;
}

.about .content {
  grid-area: content;
  margin: 50px 0 50px 0;
}

.about p {
  padding: 1rem;
  line-height: 2rem;
}

.about button {
  margin-top: 2.5rem;
}

@media screen and (max-width:1200px) {

  .about {
    width: 100vw;
  }

  .about .base-container {
    grid-template-columns: 1fr;
    grid-template-areas: "content";
    width: 100%;
  }
}