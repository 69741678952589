

.slick-slider {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.slick-list {
  position: relative;
  display: block !important;
  padding: 50px !important;
  width: 100%;
}

.slick-track {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 0;
}


@media screen and (max-width:900px) {
  .slick-slider {
    display: flex;
    flex-direction: column;
  }
}




